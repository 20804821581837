import { redirect } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import invariant from 'tiny-invariant'
import { persist, devtools } from 'zustand/middleware'
import { createStore } from 'zustand/vanilla'
import type { Kehila, UsersMe } from '~/generated/api'
import { AffiliationStatus, KehilaKeyEnum } from '~/generated/api'
import { parseJwt } from '~/utils/common'
import { api } from './api-client'
import { CacheKeys, queryClient } from './query-client'
type OnboardingStep = 'user' | 'verification' | 'affiliation' | 'done'

export const GENERATED_EMAIL = 'fakenoalemail'

export const initialAuthState = {
  accessToken: undefined,
  userId: undefined,
  refreshToken: undefined,
  onboardingStep: undefined,
  kehilaKey: undefined,
  activeAffiliationId: undefined,
}

export type AuthStore = {
  accessToken?: string
  refreshToken?: string
  userId?: number
  onboardingStep?: OnboardingStep
  kehilaKey?: `${KehilaKeyEnum}`
  switchKehilaKey?: `${KehilaKeyEnum}`
  activeAffiliationId?: number
  setAccessToken: (accessToken: string) => void
  setRefreshToken: (refreshToken: string) => void
  setUserId: (id: number) => void
  setOnboardingStep: (onboardingStep: OnboardingStep) => void
  setKehilaKey: (kehilaKey: KehilaKeyEnum) => void
  setSwitchKehilaKey: (kehilaKey?: KehilaKeyEnum) => void
  setActiveAffiliationId: (id: number) => void
  resetAuthState: () => void
}

export const authStore = createStore<
  AuthStore,
  [['zustand/devtools', never], ['zustand/persist', AuthStore]]
>(
  devtools(
    persist(
      (set) => ({
        ...initialAuthState,
        setAccessToken: (accessToken) => set({ accessToken }),
        setRefreshToken: (refreshToken) => set({ refreshToken }),
        setOnboardingStep: (onboardingStep) => set({ onboardingStep }),
        setKehilaKey: (kehilaKey) => set({ kehilaKey }),
        setSwitchKehilaKey: (switchKehilaKey) => set({ switchKehilaKey }),
        setUserId: (userId) => set({ userId }),
        setActiveAffiliationId: (activeAffiliationId) => set({ activeAffiliationId }),
        resetAuthState: () => set(initialAuthState),
      }),
      { name: 'auth_store' },
    ),
  ),
)

export function requireUserId() {
  const { userId } = authStore.getState()

  if (!userId) {
    logout()
  }

  return userId
}

export async function refreshAuthToken() {
  const { refreshToken, setAccessToken, setRefreshToken, userId } = authStore.getState()

  try {
    const { access, refresh } = await api.refreshToken.refreshTokenCreate({
      refresh: refreshToken!,
    })

    if (!access || !refresh) {
      logout()
    }
    setAccessToken(access)
    setRefreshToken(refresh)
    api.http.instance.defaults.headers['Authorization'] = `Bearer ${access}`

    const message = 'Access token refreshed for user ID ' + userId

    Sentry.addBreadcrumb({
      category: 'auth',
      message,
      level: 'info',
    })
  } catch (error) {
    logout()
  }
}

export function getActiveAffiliation(user: UsersMe) {
  const { userId, activeAffiliationId } = authStore.getState()

  const affiliation = user.affiliations.find(({ id }) => String(activeAffiliationId) === String(id))

  const affiliationApproved = affiliation?.affiliationStatus === AffiliationStatus.APPROVED

  if (!affiliationApproved) {
    throw redirect('/auth/register/pending')
  }

  if (!affiliation) {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'No active affiliation ID found for user ' + userId,
      level: 'error',
    })
    throw redirect('auth/register/affiliation')
  }

  return affiliation
}

export async function logout() {
  const { resetAuthState } = authStore.getState()
  resetAuthState()
  window.location.href = '/auth/login'
  return true
}

export async function getUserDetails() {
  const { accessToken } = authStore.getState()
  const user = await queryClient.fetchQuery({
    queryKey: [CacheKeys.GetUserDetails, accessToken],
    queryFn: () => api.users.usersMeRetrieve(),
  })
  return user
}
export async function validateUserOnboarding() {
  let { accessToken } = authStore.getState()

  if (!accessToken) {
    logout()
  } else {
    try {
      const { exp } = parseJwt(accessToken)
      if (!exp) {
        logout()
      }
      if (Date.now() >= exp * 1000) {
        await refreshAuthToken()
        queryClient.invalidateQueries()
      }
    } catch (err) {
      console.error(err)
      logout()
    }
  }

  const user = await getUserDetails()

  if (user.email && !user.email.includes(GENERATED_EMAIL) && !user.emailVerified) {
    throw redirect('/auth/register/email-verification')
  }
  if (user.phone && !user.phoneVerified && !user.emailVerified) {
    throw redirect('/auth/register/sms-verification')
  }

  const affiliation = getActiveAffiliation(user)

  return { user, affiliation }
}

export async function getKehila(props?: { ignoreHeaders?: boolean }) {
  const kehilaKey = getKehilaKey()

  const config = {}
  if (props?.ignoreHeaders) {
    config.headers = { Authorization: null }
  }

  const kehila = await queryClient.fetchQuery({
    queryKey: [
      CacheKeys.GetKehila,
      authStore.getState().kehilaKey,
      props?.ignoreHeaders,
      authStore.getState().switchKehilaKey,
    ],
    // @ts-expect-error wrong type for kehila key

    queryFn: () => api.kehilot.kehilotKeyRetrieve(kehilaKey, config),
  })

  return kehila as Kehila
}

export function getKehilaKey() {
  const switchKehila = getSwitchKehilaKey()

  const { setKehilaKey } = authStore.getState()

  const kehilaFromEnv =
    window.__NOAL_ENV__.kehila || (import.meta.env.DEV && import.meta.env.VITE_MOCK_KEHILA)

  if (kehilaFromEnv) {
    setKehilaKey(kehilaFromEnv)
  } else {
    setKehilaKey(KehilaKeyEnum.KYehudit)
  }

  const { kehilaKey } = authStore.getState()
  invariant(kehilaKey, 'No kehila key')

  if (switchKehila) {
    return switchKehila
  }

  return kehilaKey
}

export function getSwitchKehilaKey() {
  const { switchKehilaKey } = authStore.getState()
  if (switchKehilaKey) {
    return switchKehilaKey
  }
}
